@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Sentinel';
  src: url('/fonts/SentinelLight.eot'); /* IE9 Compat Modes */
  src: url('/fonts/SentinelLight.otf'),
    url('/fonts/SentinelLight.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('/fonts/SentinelLight.woff') format('woff'),
    /* Modern Browsers */ url('/fonts/SentinelLight.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('/fonts/SentinelLight.svg#SentinelLight')
      format('svg'); /* Legacy iOS */
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'NB International Pro';
  src: url('/fonts/NBInternationalProRegular.otf') font-weight-normal,
       url('/fonts/NBInternationalProBold.otf') font-weight-bold,
       url('/fonts/NBInternationalProLight.otf');
  font-display: swap;

}

@font-face {
  font-family: 'NB International Pro';
  src: url('/fonts/NBInternationalProLight.otf');
  font-weight: 300;
}

@font-face {
  font-family: 'Neue Haas Unica Pro';
  src: url('/fonts/NeueHaasUnicaPro-Medium.otf');
  font-style: normal;
  font-display: swap;
}

/* Font 1824 */
@font-face {
  font-family: NanumMyeongjo;
  src: url('https://fonts.googleapis.com/css2?family=Nanum+Myeongjo&display=swap');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Outfit;
  src: url('https://fonts.googleapis.com/css2?family=Outfit:wght@300;400;600;700&display=swap');
  font-style: normal;
  font-display: swap;
}


/* https://stackoverflow.com/questions/9450706/safari-font-weight-issue-text-too-bold/9451858#9451858 */
/* Issue: On mobile mac os, font look bold when zoom out and normal when zoom in */
/* Solution:
    1. body 
      - add antialias, remove text-stroke-width, text-rendering optimizeLegibility
      - add kern and text-anchor middle
    2. heading
      - change default heading font weight to normal instead of 700

  Root Cause:
    For rendering bold text consistently across browsers, your font should explicitly contain bold characters. Otherwise, browsers probably try to make bold variants of characters based on their normal variants, and results are inconsistent across browsers since they likely have different algorithms for such conversion.
  Note:
    Also note that text rendering may be different on different platforms on system level (e.g. Windows, Mac OS).
    Such differences are OK and do not typically need to be fixed.

*/
html,
body {
  @apply scroll-smooth marker:font-text;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0!important;
  margin: 0;
  padding: 0;
  scroll-behavior: auto;
  text-rendering: optimizeLegibility;
  /* fix iphone form packed */
  font-feature-settings: "kern";
  text-anchor: middle;
}

/* https://allthingssmitty.com/2020/05/11/css-fix-for-100vh-in-mobile-webkit/ */
body {
  min-height: 100vh;
  /* mobile viewport bug fix */
  min-height: -webkit-fill-available;
}

html {
  height: -webkit-fill-available;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

.aaco-header-wrapper {
  min-height: 100vh;
  min-height: -webkit-fill-available;
}

:root {
  /* Override react-spring-bottom-sheet */
  --rsbs-max-w: 400px;
  --rsbs-ml: auto;
  --rsbs-mr: auto;
}

/* Override react-spring-bottom-sheet */
div[data-rsbs-state] > * {
  z-index: 80;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

/* Hide Scrollbar For Webkit-based browsers (Chrome, Safari and Opera) */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* Hide Scrollbar For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.app-container {
  max-width: 100%;
  width: 100vw;
  transition: opacity 300ms ease-out;
  opacity: 1;
}
.app-container.transitioning {
  opacity: 0;
  pointer-events: none;
}

a {
  color: inherit;
  text-decoration: none;
}

/** Quick solution */
.swiper.aaco-swiper {
  @apply overflow-visible;
}
.aaco-carousel-featured .swiper {
  @apply h-full;
}
.aaco-carousel-featured .swiper .swiper-slide {
  @apply h-full;
}

.aaco-carousel-featured .swiper .swiper-wrapper {
  height: 100% !important;
}
.aaco-carousel-featured .aaco-card__image {
  height: 100% !important;
}
.aaco-carousel-featured .aaco-carousel-card__slide {
  height: 100% !important;
}

.wh .aaco-carousel-featured .aaco-carousel__content {
  background-color: #392a49;
}

.swiper .swiper-pagination-bullet {
  @apply bg-majesty-30 opacity-100 w-1.5 h-1.5 transition-all;
}
.swiper .swiper-pagination-bullet + .swiper-pagination-bullet {
  @apply ml-2;
}
.swiper .swiper-pagination-bullet-active {
  @apply w-2.5 h-2.5 bg-violet;
}

.aaco-cuts-illustrations .swiper-slide {
  width: 65vw !important;
}

@media screen(md) {
  .aaco-cuts-illustrations__grid.active {
    cursor: grabbing;
    cursor: -webkit-grabbing;
  }
}
/** Pagination **/
.aaco-pagination {
  @apply flex items-center gap-x-4;
}

.aaco-pagination li a {
  @apply font-text font-body text-body text-grey-5 px-2 py-1;
}

.aaco-pagination li.selected a {
  @apply font-text font-body-bold text-violet-bright border-b-2 border-b-violet-bright;
}

svg.cuts-outline {
  pointer-events: all;
  cursor: pointer;
}
#outlines path {
  transition: all 0.5s;
  fill-opacity: 0;
}

.aaco-cut-card .aaco-card__content {
  box-shadow: 0 0 0 0 #0000000d, 0 14px 31px 0 #0000000d,
    0 57px 57px 0 #0000000a, 0 129px 78px 0 #00000005, 0 230px 92px 0 #00000003,
    0 360px 100px 0 #00000000;
}

@media screen(sm) {
  .container {
    max-width: 100%;
  }
}

@media screen(md) {
  .aaco-two-column-layout .aaco-left-column .container {
    padding-right: 2rem;
    margin-right: unset;
  }

  .aaco-two-column-layout .aaco-right-column .container {
    padding-left: 2rem;
    margin-left: unset;
  }
}

@media screen(2xl) {
  .container {
    max-width: 1536px;
  }

  .aaco-two-column-layout .md\:col-span-4 .container:not(.no-side) {
    max-width: 768px;
  }

  .aaco-two-column-layout .md\:col-span-3 .container:not(.no-side) {
    max-width: 576px;
  }

  .aaco-two-column-layout .md\:col-span-5 .container:not(.no-side) {
    max-width: 960px;
  }
}


.aaco-dp-home__portal__external-card
  .aaco-dp-home__portal__external-card__image {
  @apply h-[329px] lg:h-[380px] flex flex-col items-center justify-center;
}

.wh .aaco-dp-home__portal__external-card .aaco-dp-home__portal__external-card__subtitle{
  @apply font-heading text-black;
  font-weight: 400;
  font-size: 26px;
  line-height: 96%;
  letter-spacing: -0.03em;
}

@media screen(lg) {
  .wh .aaco-dp-home__portal__external-card .aaco-dp-home__portal__external-card__subtitle{
    font-size: 24px;
    line-height: 110%;
  }
}

.wh .aaco-dp-home__portal__external-card .aaco-dp-home__portal__external-card__contact {
  font-size: 38px;
  line-height: 36px;
  letter-spacing: -0.03em;
}

.wh .aaco-dp-result .aaco-dp-result__title {
  font-family: 'NB International Pro'!important;
}

.wh .aaco-dp-result__filter-format .aaco-dropdown {
  @apply text-black border-black h-[32px];
  font-weight: 400;
  font-size: 11px;
}


.wh .aaco-dp-result__filter-format .aaco-dropdown svg {
  @apply text-black;
}

.wh .aaco-dp__result__section-title {
  @apply text-black;
  font-family: 'NB International Pro'!important;
  font-weight: 400;
  font-size: 26px;
  line-height: 96%;
  letter-spacing: -0.03em;
}

.wh .aaco-dp__result__section-empty {
  font-family: 'NB International Pro'!important;
  font-style: normal;
  font-weight: 300;
  font-size: 26px;
  line-height: 25px;
  letter-spacing: -0.02em;
}

.wh .aaco-dp-resource-card__title {
  font-weight: 400;
  font-size: 20px;
  line-height: 105%;
  letter-spacing: -0.03em;
}

.wh .aaco-dp-resource-card__action .aaco-dp-resource-card__action--preview,
.wh .aaco-dp-resource-card__action .aaco-dp-resource-card__language-dropdown.aaco-dropdown > div.aaco-dropdown {
  @apply font-text uppercase text-black border-black;
  font-weight: 400;
  font-size: 9.55px;
  line-height: 20px;
  letter-spacing: 0.05em;
}

.wh .aaco-dp-resource-card__action .aaco-dp-resource-card__action--preview {
  @apply font-text uppercase text-black;
}

.wh .aaco-dp-resource-card__thumbnail__metadata {
  @apply font-text;
  font-weight: 400;
  font-size: 14px;
  line-height: 110%;
}

@media screen(lg) {
  .wh .aaco-dp-result__filter-format .aaco-dropdown {
    @apply h-[40px];
  }

  .wh .aaco-dp__result__section-title {
    font-size: 34px;
  }

  .wh .aaco-dp__result__section-empty {
    font-size: 48px;
    line-height: 46px;
  }

  .wh .aaco-dp-resource-card__title {
    font-size: 24px;
    line-height: 110%;
  }

  .wh .aaco-dp-resource-card__action .aaco-dp-resource-card__action--preview,
  .wh .aaco-dp-resource-card__action .aaco-dp-resource-card__language-dropdown.aaco-dropdown > div.aaco-dropdown {
    font-size: 11px;
  }

}

/* START: CTA */
/* Secondary Button */
.aaco-cta.aaco-cta__secondary {
  @apply text-black h-[40px];
}
/* Primary Line */
.aaco-cta__primary-line.aaco-cta--invert.link-invert {
  --tw-text-opacity: 1;
  color: rgba(var(--colors-white), var(--tw-text-opacity));
}
.aaco-cta__primary-line > span {
  @apply text-black underline underline-offset-[5px] decoration-[1.5px] decoration-black;
  font-weight: 400;
}
/* END: CTA */

/* 
  ---------------------------------------------------------------------------------------------------  
  ---------------------------------------- ** DD STYLES ** ------------------------------------------  
  ---------------------------------------------------------------------------------------------------  
*/

@font-face {
  font-family: 'BentonSansCompressed Bold';
  src: url('/fonts/BentonSansCompressedBold.eot'); /* IE9 Compat Modes */
  src: url('/fonts/BentonSansCompressedBold.otf'),
    url('/fonts/BentonSansCompressedBold.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('/fonts/BentonSansCompressedBold.woff') format('woff'),
    /* Modern Browsers */ url('/fonts/BentonSansCompressedBold.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('/fonts/BentonSansCompressedBold.svg#BentonSansCompressed-Bold')
      format('svg'); /* Legacy iOS */
  font-style: normal;
  font-weight: bold;
  font-display: swap;
}


@font-face {
  font-family: 'Godo B';
  src: url('/fonts/GodoB.eot'); /* IE9 Compat Modes */
  src: url('/fonts/GodoB.otf'),
    url('/fonts/GodoB.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('/fonts/GodoB.woff') format('woff'),
    /* Modern Browsers */ url('/fonts/GodoB.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('/fonts/GodoB.svg#GodoB0')
      format('svg'); /* Legacy iOS */
  font-style: normal;
  font-display: swap;
}

.dd-en .aaco-dp-home__portal__external-card .aaco-dp-home__portal__external-card__subtitle{
  @apply font-heading text-black uppercase text-h6-mobile font-h6-mobile;
}

@media screen(lg) {
  .dd-en .aaco-dp-home__portal__external-card .aaco-dp-home__portal__external-card__subtitle{
    @apply text-h6 font-h6 text-progressive-blue;
  }
}

.dd-en .aaco-dp-home__site-grid .aaco-cta__secondary span, 
.dd-en .aaco-dp-story__content__cta .aaco-cta span {
  @apply capitalize;
}

.dd-en .aaco-dp-result__filter-format .aaco-dropdown {
  @apply font-text-roboto text-button-and-tag font-button-and-tag text-grey-5 border-grey-5 h-[32px];
}

@media screen(lg) {
  .dd-en .aaco-dp-result__filter-format .aaco-dropdown {
    @apply font-text text-small-bold font-small-bold text-grey-5 border-grey-5 h-[40px];
  }

  .dd-en .aaco-dp-result__filter-format .aaco-dropdown svg {
    @apply text-grey-5;
  }
}

.dd-en .aaco-dp-resource-card__language-dropdown .aaco-dropdown {
  @apply text-body-text-grey uppercase border-grey-dd;
  font-size: 11px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.05em;
}

.dd-en .aaco-dp-resource-card__language-dropdown .aaco-dropdown svg{
  @apply text-progressive-blue;
}


/* START: CTA */
/* Secondary Button */
.dd-en .aaco-cta.aaco-cta__secondary {
  @apply text-progressive-blue h-[40px];
}
/* Primary Line */
.dd-en .aaco-cta__primary-line.aaco-cta--invert.link-invert {
  --tw-text-opacity: 1;
  color: rgba(var(--colors-white), var(--tw-text-opacity));
}
.dd-en .aaco-cta__primary-line > span {
  @apply font-text text-button-kr font-button-kr underline underline-offset-[5px] decoration-[1.5px] decoration-actual-dd-gold;
}

@media screen(lg) {
    .dd-en .aaco-cta__primary-line > span {
    @apply font-text-kr;
  }
}

/* END: CTA */


/* Shimmer */
.shimmer {
  background: linear-gradient(91.23deg, #F5F6F7 17.74%, #E6E6E6 40.13%, #F5F6F7 63.61%, #EDEEEE 85.01%)!important;
}
.shimmer-thumbnail, .shimmer-title {
  margin-bottom: 15px!important;
}

.shimmer-title-line {
  border-radius: 0!important;
  height: 33px!important;
}


.shimmer-title--secondary {
  margin-bottom: 13px!important;
}

.shimmer-title--secondary .shimmer-title-line {
  height: 23px!important;
}

.loading__title {
  margin-bottom: 0!important;
}

.loading__thumbnail--small .shimmer-thumbnail{
  margin-bottom: 0!important;
}


/* Hardcode height for AACo iframe form */
.aaco-dp-feedback__title {
  @apply mx-auto px-[30px];
}

.aaco-dp-feedback iframe {
  @apply w-full px-[30px];
  position: absolute;
  overflow: hidden!important;
}

#feedback-form,
.aaco-dp-feedback iframe {
  height: 724px!important;
}

@media (min-width: 360px) {
  #feedback-form,
  .aaco-dp-feedback iframe {
    height: 689px!important;
  }
}

@media (min-width: 375px) {
  #feedback-form,
  .aaco-dp-feedback iframe {
    height: 724px!important;
  }
}

@media (min-width: 390px) {
  #feedback-form,
  .aaco-dp-feedback iframe {
    height: 708px!important;
  }
}

@media (min-width: 540px) {
  #feedback-form,
  .aaco-dp-feedback iframe {
    height: 640px!important;
  }
}

@media screen(md) {
  .aaco-dp-feedback__title,
  .aaco-dp-feedback iframe {
    max-width: min(100vw, 840px)!important; 
  }
}

@media (min-width: 912px) {
  #feedback-form,
  .aaco-dp-feedback iframe {
    height: 600px!important;
  }
}